<template>
    <div class="homelist">
        <div class="container">
            <div class="brand">
                <el-breadcrumb separator-class="el-icon-arrow-right" v-if="pid">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>产品中心</el-breadcrumb-item>
                </el-breadcrumb>
                <el-breadcrumb separator-class="el-icon-arrow-right" v-else-if="searchValue">
                    <el-breadcrumb-item>全部结果</el-breadcrumb-item>
                    <el-breadcrumb-item>"{{searchValue}}" 共搜到商品{{total}}件</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div style="margin-top:20px;">
                <div class="tag-con" v-if="tags.length>0">
                    <div class="title">已选条件:</div>
                    <el-tag v-for="tag in tags" :key="tag.name" closable effect="plain" style="margin-right:10px;" @close="removeTag">
                        {{tag.name}}
                    </el-tag>
                    <div class="clear" @click="toclear">清空筛选条件</div>
                </div>
            </div>
            
            <div class="class-list">
                <div class="title" style="width:85px;">全部产品:</div>
                <template v-if="!checked[0]">
                     <div class="class-con" v-for="(item,index) in class_list_all" :key="item.cid" @click="toadd(item.pname,item.cid,class_list_all[index].children[0].cid,class_list_all[index].children[0].pname)">
                        <div class="item">
                            {{item.pname}}
                        </div>
                    </div>
                </template>
               <template v-else>
                   <div class="class-con" style="padding:13px 20px">

                   </div>
               </template>
                
            </div>
            <div class="class-list" v-if="brand_list.length>0" >
                <div class="title" style="width:85px;">品牌:</div>
                <template v-if="!checked[0]">
                    <div style="display:flex;align-items:center;flex-wrap:wrap;flex:1;">
                        <div class="class-con" v-for="item in brand_list" :key="item.brand_id" @click="addbrand(item.brand_name,item.brand_id)">
                            <div class="item">
                                {{item.brand_name}}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div style="flex:1;">
                        <el-checkbox-group v-model="checkList" style="width:100%;display:flex;align-items:center;padding: 5px 0px;flex-wrap:wrap;" >
                            <el-checkbox :label="item.brand_name" v-for="item in brand_list" :key="item.brand_id" style="width:15%;" @change="brandAddall(item.brand_id)"></el-checkbox>
                        </el-checkbox-group>
                    </div>
                </template>
                <el-button class="el-icon-plus" size="mini" @click="clickChecked(0)">
                    {{checked[0]?'单选':'多选'}}
                </el-button>
            </div>
            <div class="class-list" v-if="class_list.length>0">
                <div class="title" style="width:85px;">分类:</div>
                <template v-if="!checked[1]">
                    <div class="class-con" v-for="item in class_list" :key="item.cid" @click="toclassify(item.cid,item.pname)">
                        <div class="item">
                            {{item.pname}}
                        </div>
                    </div>
                </template>
                <template v-else>
                    <el-checkbox-group v-model="checkList" style="width:100%;display:flex;align-items:center;padding: 5px 0px;">
                        <el-checkbox :label="item.pname" v-for="item in class_list" :key="item.cid" style="width:10%;" @change="brandAddall1(item.cid)"></el-checkbox>
                    </el-checkbox-group>
                </template>
                <el-button class="el-icon-plus" size="mini" @click="clickChecked(1)">
                    {{checked[1]?'单选':'多选'}}
                </el-button>
            </div>
            <div class="class-list" style="border-bottom:1px solid rgb(238, 238, 238)">
                <div class="title" style="width:85px;">价格:</div>
                <div class="class-con" v-for="(item,index) in pricelist" :key="index" @click="addprice(item)">
                    <div class="item">
                         ￥{{item}}
                    </div>
                </div>
                <div class="input-con">
                    ￥
                    <el-input v-model="low"></el-input>
                    -
                    <el-input v-model="high"></el-input>
                    <el-button class="surebtn" size="mini" @click="rangeselect">确定</el-button>
                </div>
            </div>

            <div class="default">
                <div class="default-con">
                    <div class="title1" :style="{'color': (order==1 ? '#020202':'#999999')}" @click="changeorder(1)">默认</div>
                    <div class="title1 price" @click="changeorder(2)" :style="{'color': (order==2 ? '#020202':'#999999')}">
                        <div>价格</div>
                        <div class="arrow-con">
                            <i class="el-icon-caret-top" :style="{'color':(order_arrow&&order==2?'#020202':'#999999')}" @click="changeArrow(true)"></i>
                            <i class="el-icon-caret-bottom" style="margin-top:-6px;" :style="{'color':(!order_arrow&&order==2?'#020202':'#999999')}" @click="changeArrow(false)"></i>
                        </div>
                    </div>
                    <div class="title1" @click="changeorder(3)" :style="{'color': (order==3 ? '#020202':'#999999')}">销量</div>
                </div>
            </div>

            <div class="pageation">
                <div class="page-con" v-for="item in list" :key="item.id" @click="todetail(item.id)">
                    <img :src="item.imgurl"/>
                    <div style="padding:20px 0">
                        <div class="comidty-title">{{item.product_title}}</div>
                        <div class="price-title">￥{{item.price}}</div>
                    </div>
                </div>
            </div>
            <div style="width:100%;display:flex;" v-if="list.length>0">
                <div style="margin:30px auto 0;">
                    <el-pagination :page-size="16" :current-page="page" @current-change="currentChange" background layout="prev, pager, next" :total="total">
				</el-pagination>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import {useRoute} from 'vue-router'
import {onMounted, reactive, toRefs,ref} from 'vue'
import {getData} from '@/utils/req'
import {useRouter} from 'vue-router'
export default {
    setup(){
        const route = useRoute()
        const router = useRouter()
        let state = reactive({
            pid:'',
            pid_list:[],
            pname:'',
            pnames:'',
            cid:'',
            searchValue:'',
        })
        const state1 = reactive({
        tags: [

          ]
          ,length:1
        })
        let checkList = ref([])
        let goodstate = reactive({
            brand_list:'',
            class_list:'',
            list:'',
            class_list_all:'',
            total:'',
            page:1,
            order_mode:'asc',
            brand_id:'',
            amount:'',
            brand_id_list:[]
            
        })

        const todetail = (id)=>{
            const { href } = router.resolve({
                path: '/homedetail',
                query: {
                    good_id:id
                }
            });
            window.open(href, "_blank");
        }
        const pricelist= ref([
            '0-449','500-1000','1000-1500','1500以上'
        ])
        const checked = ref([
           false,false
        ])
        let pc_user = reactive({
            access_id:''
        })
        let range = reactive({
            low:'',
            high:''
        })
        let goodnum = ref('')
        let order =ref(1)
        let order_arrow = ref(true)
        onMounted(()=>{
            if(route.query.brand){
                state.pid = ''
                goodstate.brand_id = route.query.pid
            }
            else{
                state.pid = route.query.pid
            }
            state.pname = route.query.pname
            state.searchValue = route.query.sValue
            state.cid = route.query.cid
            state.pnames = route.query.pnames
            pc_user.access_id = JSON.parse(localStorage.getItem("pc_user")).access_id
            if(state.pnames){
                state1.tags.push({'name':state.pnames})
            }
            if(state.pname){
                state1.tags.push({'name':state.pname})
            }
            axios()
        })
        const axios =()=>{
            const data = {
                module: "app_pc",
                action: "index",
                m: "search",
                cid: state.cid,
                keyword:state.searchValue, 
                class_id: state.pid,
                brand_id:goodstate.brand_id, 
                amount:goodstate.amount, 
                sort_type:order.value ==3?'volume':'', 
                sort:order.value ==2?goodstate.order_mode:'', 
                page: goodstate.page
            }
            getData(data).then(res=>{
                goodstate.brand_list = res.data.brand_list
                goodstate.class_list = res.data.class_list
                goodstate.class_list_all =res.data.class_list_all
                goodstate.list = res.data.list
                goodstate.total = res.data.total
            })
        }
        const toclear =()=>{
            state1.tags = []
            checkList.value = []
            goodstate.brand_id = ''
            goodstate.brand_id_list = []
            axios()
        }
        const clickChecked = (value)=>{
            checked.value[value] = !checked.value[value]
        }
        const currentChange =(e)=>{
            goodstate.page =e
            axios()
            // goodstate.page = 1
        }
        const changeorder = (value)=>{
            order.value = value
            axios()
        }

        // 移除标签
        const removeTag = (tag)=>{
            state1.tags.splice(state1.tags.indexOf(tag),1)
            checkList.value.splice(state1.tags.indexOf(tag),1)
            goodstate.brand_id_list.splice(state1.tags.indexOf(tag),1)
            if(goodstate.brand_id !==''){
                goodstate.brand_id =''
            }
            for(let i=0;i<goodstate.brand_id_list.length;i++){
                goodstate.brand_id = goodstate.brand_id +','+goodstate.brand_id_list[i]
                if(goodstate.brand_id[0] ==','){
                    goodstate.brand_id = goodstate.brand_id.slice(1,)
                }
            }
            if(state.pid !== ''){
                state.pid = ''
            }
            for(let i=0;i<state.pid_list.length;i++){
                state.pid = state.pid + ','+ state.pid_list[i]
                if(state.pid[0] == ','){
                    state.pid = state.pid.slice(1,)
                }
            }
            axios()
        }
        const changeArrow = (value)=>{
            order_arrow.value = value
            if(value){
                goodstate.order_mode = 'asc'
            }
            else{
                goodstate.order_mode = 'desc'
            }
        }
        const toadd =(value,cid,pid,pname)=>{
            if(value != state1.tags[0]['name']){
                state1.tags[0]['name']  = value
                state1.tags[1]['name'] = pname
                state.cid =cid
                state.pid = pid
                goodstate.page =1
                axios()
            }
        }
        const addbrand = (v,brand_id)=>{
            console.log(brand_id)
            if(!checked.value[0]){
                if(state1.tags.length >1){
                    state1.tags[1]['name'] = v
                    goodstate.brand_id =brand_id
                }
                else{
                    state1.tags[0]['name'] = v
                    goodstate.brand_id =brand_id
                }
                axios()
            }
        }
        const toclassify = (value,pname) =>{
            if(!checked.value[1]){
                state.pid = value
                state1.tags[1]['name'] = pname
                axios()
            }
        }
        const addprice = (v) =>{
            goodstate.amount  = v
            axios()
        }
        const rangeselect = ()=>{
            goodstate.amount = range.low+'-'+range.high
            axios()
        }
        const brandAddall = (e) =>{
            if(goodstate.brand_id_list.includes(e)){
                goodstate.brand_id_list.splice(goodstate.brand_id_list.indexOf(e),1)
            }
            else{
                goodstate.brand_id_list.push(e)
            }
            if(goodstate.brand_id !==''){
                goodstate.brand_id =''
            }
            for(let i=0;i<goodstate.brand_id_list.length;i++){
                goodstate.brand_id = goodstate.brand_id +','+goodstate.brand_id_list[i]
                if(goodstate.brand_id[0] ==','){
                    goodstate.brand_id = goodstate.brand_id.slice(1,)
                }
            }
            state1.tags = []
            for(let i=0;i<checkList.value.length;i++){
                state1.tags.push({
                    "name":checkList.value[i]
                })
            }
            axios()
        }
        const brandAddall1 = (e) =>{
            if(state.pid_list.includes(e)){
                state.pid_list.splice(state.pid_list.indexOf(e),1)
            }
            else{
                state.pid_list.push(e)
            }
            if(state.pid !==''){
                state.pid =''
            }
            for(let i=0;i<state.pid_list.length;i++){
                state.pid = state.pid + ','+state.pid_list[i]
                if(state.pid[0] == ','){
                    state.pid = state.pid.slice(1,)
                }
            }
            state1.tags = []
            for(let i=0;i<checkList.value.length;i++){
                state1.tags.push({
                    "name":checkList.value[i]
                })
            }
            axios()
        }
        return {
            goodnum,
            pricelist,
            checked,
            order,
            order_arrow,
            checkList,
            ...toRefs(state),
            ...toRefs(state1),
            ...toRefs(goodstate),
            ...toRefs(range),
            toclear,
            clickChecked,
            currentChange,
            changeorder,
            changeArrow,
            toadd,
            addbrand,
            toclassify,
            addprice,
            rangeselect,
            brandAddall,
            brandAddall1,
            removeTag,
            todetail
        }
    }
}
</script>
<style scoped>
    .homelist{
        width: 100%;
        border-top: 1px solid rgb(230, 230, 230);
    }
    .container{
        width: 1200px;
        margin: 30px auto 0;
    }
    .tag-con{
        position: relative;
        margin-top: 20px;
        display: flex;
        align-items: center;
        background-color: #F6F6F6;
        padding: 10px 20px;
    }
    .tag-con .clear{
        cursor: pointer;
        font-size: 14px;
        color: #999999;
        position: absolute;
        right: 20px;
    }
    .title{
        font-weight: bold;
        font-size: 14px;
        color: #020202;
        margin-right: 10px;
    }
    .title1{
        font-weight: bold;
        font-size: 14px;
        margin-right: 10px;
        cursor: pointer;
    }
    .class-list{
        display: flex;
        align-items: center;
        position: relative;
        border-top: 1px solid rgb(238, 238, 238);
        padding: 10px 20px;
    }
    .class-list .class-con{
        /* display: flex;
        align-items: center; */
        width: 15%;
        color: #020202;
        font-size: 14px;
        line-height: 28px;
        padding-right: 10px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .class-list .class-con:hover{
        color: #D4282D;
    }
    .el-icon-plus{
        position: absolute;
        right: 20px;
    }
    .input-con{
        display: flex;
        align-items: center;
    }
    .el-input{
        
        font-size: 10px;
    }
    ::v-deep .el-input__inner{
        width: 60px;
        height: 28px;
        margin: 0 5px;
        border-radius: 0;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
    }
    ::v-deep .el-checkbox:hover .el-checkbox__label{
        color: #D4282D!important;
    }
    .surebtn{
        background-color: #020202!important;
        color: #ffffff!important;
    }
    .input-con{
        position: absolute;
        right: 20px;
    }
    .default{
        width: 100%;
        margin-top: 20px;
    }
    .default .default-con{
        display: flex;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        padding: 15px 20px;
        background-color: #F6F6F6;
        box-sizing: border-box;
    }
    .default-con .price{
        display: flex;
        align-items: center;
    }
    .arrow-con{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .pageation{
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
    }
    .page-con{
        width: 286px;
        margin-right: 16px;
        margin-bottom: 20px;
    }
    .page-con:hover{
        box-shadow:0px 1px 8px 0px rgba(0, 0, 0, 0.2);
        background-color: #eeeeee;
    }
    .page-con:nth-child(4n){
        margin-right: 0px;
    }
    .page-con img{
        width: 286px;
        height: 286px;
    }
    .comidty-title{
        color: #020202;
        font-weight: bold;
        font-size: 14px;
        text-align: center;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 auto;
    }
    .price-title{
        display: block;
        font-size: 14px;
        line-height: 12px;
        color: #D4282D;
        margin-top: 6px;
        text-align: center;
    }
</style>